import React, { useContext, useEffect, useRef, useState } from "react"
import { graphql, Link } from "gatsby"
import { FaChevronLeft } from "react-icons/fa"
import styled from "@emotion/styled"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { SiteContext, ContextProviderComponent } from "../context/mainContext"
import BaseContext from "../context/baseContext"

import Seo from "../components/seo"
import Footer from "../components/Footer"
import StyledTitle from "../components/StyledTitle"
import ContactForm from "../components/formComponents/ContactForm"
import GImage from "../components/GImage"
import QuantityPicker from "../components/QuantityPicker"

import useResize from "../utils/useResize"
import { slugify, numberFormat } from "../utils/helpers"

const InsetBoxShadow = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  box-shadow: 0px -40px 160px 50px rgba(0, 0, 0, 0.6) inset;
  -webkit-box-shadow: 0px -40px 160px 50px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0px -40px 160px 50px rgba(0, 0, 0, 0.6) inset;
`
const params = {
  contactForm: {
    defaultSubject: "acheter",
    title: {
      text: "Contactez-nous",
      variant: "h3",
      component: "h3",
    },
  },
}

const WineTemplate = (props) => {
  const { vigne2, bouchon, directus } = props.data
  // const wineContext = props.pageContext.content

  const [numberOfitems, updateNumberOfItems] = useState(1)
  // const [language, setLanguage] = useState("fr")
  const componentRef = useRef(null)
  const headerRef = useContext(BaseContext)
  const { width } = useResize(componentRef)

  // const changeLang = () => {
  //   setLanguage(language === "fr" ? "be" : "fr")
  // }

  const {
    context: { addToCart, setItemQuantity, getCartItem, removeFromCart },
  } = props

  useEffect(() => {
    if (getCartItem(directus.wine) >= 1) {
      console.log(
        "useEffect, updateNumberOfItems with getCartItem",
        getCartItem(directus.wine)
      )
      updateNumberOfItems(getCartItem(directus.wine))
    }
  }, [getCartItem, directus.wine])

  function addItemToCart(item) {
    item["quantity"] = numberOfitems
    addToCart(item)
  }

  console.log("render...")

  function increment(item) {
    console.log({
      itemQuantity: item.quantity,
      numberOfitems: numberOfitems,
      getCartItem: getCartItem(item),
    })
    item.quantity = numberOfitems + 1
    updateNumberOfItems(item.quantity)
    if (getCartItem(item) === 0) return
    setItemQuantity(item)
  }

  function decrement(item) {
    // if (getCartItem(item) >= 1) return
    item.quantity = numberOfitems - 1

    if (item.quantity <= 0 && getCartItem(item) >= 1) {
      removeFromCart(item)
      item.quantity = 1
      updateNumberOfItems(1)
    }
    updateNumberOfItems(item.quantity)
    if (getCartItem(item) === 0) return
    setItemQuantity(item)
  }

  function changeQuantity(e, item) {
    const targetVal = parseInt(e?.target?.value)
    console.log(targetVal)
    if (targetVal && typeof targetVal === "number" && targetVal >= 0) {
      if (targetVal <= 0 && getCartItem(item) >= 1) {
        removeFromCart(item)
        item.quantity = 1
        updateNumberOfItems(1)
      } else {
        item.quantity = targetVal
        updateNumberOfItems(targetVal)
        if (getCartItem(item) === 0) return
        setItemQuantity(item)
      }
    }
  }

  return (
    <>
      {directus.wine && (
        <>
          <Seo
            title={`Vignoble de Jape-loup - ${directus.wine.name}`}
            description={directus.wine.description}
          />
          <section className="relative" ref={headerRef}>
            <InsetBoxShadow />
            {/* // willChange: "transform",
                // transform: `translate(0, ${styleTopIllustration})`,
                // WebkitTransform: `translate(0, ${styleTopIllustration})`,
                // OTransform: `translate(0, ${styleTopIllustration})`,
                // MozTransform: `translate(0, ${styleTopIllustration})`, */}
            <GImage
              objectPosition="50% 70%"
              imgStyle={{
                objectFit: "cover",
                minHeight: "85vh",
                maxHeight: "110vh",
                height: "auto",
                width: "100%",
                margin: 0,
                aspectRatio: "inherit",
                top: width < 753 ? 0 : 0,
                left: width < 753 ? 0 : 0,
                right: 0,
              }}
              style={{ display: "block" }}
              className="block h-85vh w-full lg:ml-auto lg:mr-0"
              src={directus.wine.illustration.imageFile}
              alt="Illustration de la dégustation"
            />
          </section>
          <div className="items-center pb-20" ref={componentRef}>
            <Link
              to={`/${slugify("Les Vins")}/#${slugify("Les Vins")}`}
              className="px-4 pt-4 inline-block lg:absolute"
            >
              <span className="hidden lg:block font-bold text-gray-500">
                <FaChevronLeft className="inline" /> Revenir à la liste des vins
              </span>
              <span className="inline-block lg:hidden font-bold text-gray-500">
                <FaChevronLeft className="inline" /> Retour
              </span>
            </Link>
            <div className="pt-8 pb-6 text-center">
              <h1 className="mb-2 text-5xl font-bold">{directus.wine.name}</h1>
              <h2 className="text-lg font-bold text-stone-400">
                {directus.wine.cepages}
              </h2>
            </div>
            <div className="lg:px-6 pt-4 pb-12 max-w-c_medium mx-auto text-lg">
              <div className="grid grid-cols-1 md:grid-cols-6 gap-8 px-0">
                <div className="col-span-1 md:col-span-3 lg:col-span-3 text-center md:text-right">
                  {/* <Popup
              modal
              trigger={
                <img
                  src={imgIntro}
                  className="max-w-lg m-0 max-h-96 w-64 md:w-full"
                  alt="Inventory item"
                />
              }
            >
            </Popup> */}
                  {directus.wine.img_degustation &&
                    directus.wine.img_degustation.imageFile && (
                      <GImage
                        imgStyle={{
                          objectFit: "contain",
                          width: "auto",
                          margin: width < 753 ? "auto" : "0 0 0 auto",
                          left: width < 753 ? 0 : "auto",
                          right: 0,
                        }}
                        className="mb-0 h-120 lg:w-auto lg:ml-auto lg:mr-0"
                        src={directus.wine.img_degustation.imageFile}
                        alt="Illustration de la dégustation"
                      />
                    )}
                </div>
                <div className="col-span-1 md:col-span-3 lg:col-span-3 px-6 bg-stone-50 border-2 border-stone-100 py-6 lg:py-8">
                  <div className="h-full flex flex-col">
                    <div>
                      <p className="mb-2 font-bold uppercase">
                        {"Note de dégustation"}
                      </p>
                    </div>
                    <div
                      className="text-stone-600 text-lg"
                      dangerouslySetInnerHTML={{
                        __html: `<div>${directus.wine.description}</div>`,
                      }}
                    />
                    {JSON.parse(process.env.GATSBY_ECOMMERCE) === true ? (
                      <div className="flex justify-start items-start gap-8">
                        <p className="text-2xl text-stone-500 italic tracking-normal inline-block border-b-2 border-secondary">
                          {numberFormat(directus.wine.price_fr)}
                        </p>
                      </div>
                    ) : (
                      <React.Fragment>
                        {/* {directus.wine.price_fr && directus.wine.price_be ? (
                          <div className="flex justify-start items-start gap-8">
                            <p className="text-2xl text-stone-500 italic tracking-normal inline-block border-b-2 border-secondary">
                              {language === "fr"
                                ? numberFormat(directus.wine.price_fr)
                                : numberFormat(directus.wine.price_be)}
                            </p>
                            <button
                              className="mt-2"
                              title="Changer de pays"
                              onClick={changeLang}
                            >
                              {language === "fr" ? (
                                <img
                                  className="w-7 opacity-75"
                                  src={"/images/France-icon.png"}
                                  alt="Jape-loup France"
                                />
                              ) : (
                                <img
                                  className="w-7 opacity-75"
                                  src={"/images/Belgium-icon.png"}
                                  alt="Jape-loup Belgique"
                                />
                              )}
                            </button>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </React.Fragment>
                    )}

                    {JSON.parse(process.env.GATSBY_ECOMMERCE) === true ? (
                      <div className="relative flex flex-auto gap-4">
                        <div
                          className={`h-auto flex self-end ${
                            getCartItem(directus.wine) >= 1 ? "w-full" : ""
                          }`}
                        >
                          <QuantityPicker
                            hideQuantityLabel={true}
                            numberOfitems={numberOfitems}
                            showButtons={getCartItem(directus.wine) >= 1}
                            changeQuantity={(e) =>
                              changeQuantity(e, directus.wine)
                            }
                            increment={() => increment(directus.wine)}
                            decrement={() => decrement(directus.wine)}
                          />
                        </div>
                        <div
                          className={`h-auto flex self-end ${
                            getCartItem(directus.wine) >= 1
                              ? "hidden"
                              : "w-full"
                          }`}
                        >
                          <button
                            className="flex w-full h-auto shadow-md shadow-slate-100 hover:shadow-slate-300 font-bold justify-center text-center px-6 py-3 text-base sm:text-lg text-white bg-emerald-400 hover:bg-emerald-600 active:bg-emerald-600 transition ease-in-out duration-150 disabled:opacity-40 disabled:cursor-not-allowed rounded-sm"
                            onClick={() => addItemToCart(directus.wine)}
                          >
                            <span className="whitespace-nowrap">
                              Ajouter au panier
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <AnchorLink
                        title="Passer commande via notre formulaire de contact"
                        to="#contact"
                        className="inline-block h-auto w-fit px-6 py-3 text-base text-gray-500 bg-white hover:bg-stone-50 border-2 border-stone-100 focus:border-gray-300 active:bg-gray-200 transition ease-in-out duration-150 disabled:opacity-40 disabled:cursor-not-allowed rounded-sm"
                      >
                        Passer commande
                      </AnchorLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 mt-2 gap-8 px-0 lg:px-6 py-8">
              <div
                className={`${
                  directus.wine.img_degustation_2 &&
                  directus.wine.img_degustation_2.imageFile
                    ? "lg:col-span-2 order-last md:order-first"
                    : "max-w-c_medium mx-auto px-6 lg:col-span-3"
                }`}
              >
                <div className="bg-stone-50 border-2 border-stone-100 px-10 pb-4 pt-12 text-lg text-left">
                  <div className="text-center mt-4">
                    <StyledTitle
                      text="En savoir plus"
                      component="h3"
                      className="h1-variant font-bold inline-block mx-auto"
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-2 gap-x-6 my-12 max-w-c_large mx-auto text-stone-800">
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">Région</p>
                      <div className="text-stone-500">
                        {directus.wine.region}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Cépages
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.cepages}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Appellation - Centilisation
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.appellation}
                      </div>
                    </div>

                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Âge des vignes
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.age_des_vignes}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Rendement
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.rendement}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">Sol</p>
                      <div className="text-stone-500 col-span-1 md:col-span-4">
                        {directus.wine.sol}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Culture
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.culture}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Vendanges
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.vendanges}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Intrants oenologiques
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.intrants_oenologiques}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">Alcool</p>
                      <div className="text-stone-500">
                        {directus.wine.alcool}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        SO2 Total
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.so2_total}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Potentiel de garde
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.potentiel_garde}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Stockage
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.stockage}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        Vinification et élevage
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.vinification_elevage.map(function (
                          item,
                          key
                        ) {
                          return (
                            <React.Fragment key={key}>
                              {item}
                              <br />
                            </React.Fragment>
                          )
                        })}
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold mb-1 uppercase text-sm">
                        T° de service
                      </p>
                      <div className="text-stone-500">
                        {directus.wine.temperature_service.map(function (
                          item,
                          key
                        ) {
                          return (
                            <React.Fragment key={key}>
                              {item}
                              <br />
                            </React.Fragment>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {directus.wine.img_degustation_2 &&
                directus.wine.img_degustation_2.imageFile && (
                  <div className="lg:col-span-1 text-center lg:text-left">
                    <GImage
                      imgStyle={{
                        objectFit: "contain",
                        width: "auto",
                        margin: width < 1009 ? "auto" : 0,
                        left: width < 1009 ? 0 : 0,
                        right: 0,
                      }}
                      className="w-auto mb-0 h-full max-h-140 mx-auto lg:ml-0 lg:mr-auto object-contain"
                      src={directus.wine.img_degustation_2.imageFile}
                      alt="Illustration de la dégustation"
                    />
                  </div>
                )}
            </div>
            <div>
              <GImage
                objectPosition="center center"
                objectFit="cover"
                className="w-full h-80"
                imgClassName="w-full h-80"
                src={vigne2.childImageSharp}
                alt={`Photo des vignes du domaine de Jape-loup`}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-6 gap-y-2 gap-x-6 px-8 lg:px-0 py-20 max-w-c_large mx-auto">
              <div className="col-span-1 lg:col-span-3 order-last lg:order-first">
                {/* <GImage
              className="h-120 w-full object-cover"
              src={directus.wine.illustration.imageFile}
              alt=""
            /> */}
                <GImage src={bouchon} className="w-full object-cover" />
              </div>
              <div
                id="contact"
                className="bg-stone-50 border-2 border-stone-100 col-span-1 lg:col-span-3 pt-20 pb-6 px-8 sm:px-20 relative"
              >
                <StyledTitle
                  text={params.contactForm.title.text}
                  component="h3"
                  className="h1-variant font-bold"
                  darkTheme={false}
                />
                <ContactForm
                  defaultSubject={params.contactForm.defaultSubject}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {directus.pages_settings.mentions && (
        <Footer mentions={directus.pages_settings.mentions} />
      )}
    </>
  )
}

function WineTemplateWithContext(props) {
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {(context) => <WineTemplate {...props} context={context} />}
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}

export default WineTemplateWithContext

export const query = graphql`
  query ($id: ID!) {
    # locales: allLocale(filter: { language: { eq: $language } }) {
    #   edges {
    #     node {
    #       ns
    #       data
    #       language
    #     }
    #   }
    # }
    vigne2: file(relativePath: { eq: "les-vins/vigne2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
    bouchon: file(relativePath: { eq: "les-vins/bouchon2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
    directus: allDirectus {
      pages_settings {
        mentions
      }
      wine: wines_by_id(id: $id) {
        id
        name
        slug
        description
        age_des_vignes
        alcool
        appellation
        cepages
        culture
        intrants_oenologiques
        potentiel_garde
        price_be
        price_fr
        region
        rendement
        so2_total
        sol
        stockage
        temperature_service
        vendanges
        vinification_elevage
        current_inventory
        illustration {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 80
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#f3f3ed" }
              )
            }
          }
        }
        img_degustation {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                width: 600
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#f3f3ed" }
              )
            }
          }
        }
        img_degustation_2 {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                width: 600
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#f3f3ed" }
              )
            }
          }
        }
      }
    }
  }
`
